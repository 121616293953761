const getters = {
  token: state => state.index.token,
  userInfo: state => state.index.userInfo,
  roles: state => state.index.roles,
  permission_routes: state => state.permission.routes,
  clientData: state => state.index.clientData, // 客户端回调数据
  isRefreshData: state => state.index.isRefreshData, // 头部刷新页面数据
  openedBrowser: state => state.index.openedBrowser, // 已经打开的浏览器UUID
  subAccNum: state => state.index.subAccNum, // 子账户已使用数量
  shopAccNum: state => state.index.shopAccNum, // 店铺已使用数量
  selectedId: state => state.index.selectedId,
}
export default getters
