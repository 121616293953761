<template>
  <router-view class="content"></router-view>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>
.content{
  width: 1620px;
  height: calc(100vh - 112px);
  padding: 0 74px 0 110px;
  overflow-y: auto;
}
</style>