import store from '@/store'
// 调用chromeSDK异步方法之后 返回的数据 chromeSDK同步方法直接返回数据
export function windowRegistData() {
    return new Promise((resolve, reject) => {
        window.register((res) => {
            console.log('----------回调开始----------')
            let data = JSON.parse(res)
            console.log(data)
            store.commit('index/SET_CLIENTDATA', data)
            console.log('----------回调结束----------')
            resolve(data)
        })
    })
}

// 客户端缓存 JSON对象格式
let obj = {}
if(window.ChromeSDK.cacheinfo.indexOf('LoginInfo') !== -1){
    obj.LoginInfo = JSON.parse(window.ChromeSDK.cacheinfo).LoginInfo
}else{
    obj.LoginInfo = []
}
export function setWindowCache(key, value) {
    obj[key] = value
    window.ChromeSDK.cacheinfo = JSON.stringify(obj)
}

export function getWindowCache() {
    let data = JSON.parse(window.ChromeSDK.cacheinfo)
    return data
}

export function isJsonString(str) {
    if (typeof str == 'string') {
        try {
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
}
