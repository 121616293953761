<template>
  <div class="main-wrap">
    <div class="main-content">
      <div class="banner-wrap">
        <img class="logo" src="@/assets/imgs/logo.png" alt="" />
        <img class="banner" src="@/assets/imgs/login_banner.png" alt="" />
        <img class="login-img" src="@/assets/imgs/login_img.png" alt="" />
      </div>
      <div class="login-wrap">
        <svg-icon
          icon-class="back-arrow"
          v-if="formType !== 'register' && formType !== 'login'"
          @click="formType = 'login'"
        />
        <template v-if="formType === 'register'">
          <div class="form-title">注册</div>
          <el-form ref="registForm" :model="registForm" :rules="registRules">
            <el-form-item label="邮箱账号" prop="email">
              <el-input
                v-model="registForm.email"
                placeholder="请输入邮箱账号"
              ></el-input>
            </el-form-item>
            <el-row type="flex" align="center">
              <el-col :span="14">
                <el-form-item label="验证码" prop="code">
                  <el-input
                    v-model="registForm.code"
                    placeholder="请输入验证码"
                    maxlength="6"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="8"
                :offset="2"
                style="display: flex; align-items: center; justify-content: end"
              >
                <el-button
                  class="code-btn"
                  :disabled="codeButtonStatus.status"
                  @click="getSms(registForm.email)"
                  >{{ codeButtonStatus.text }}</el-button
                >
              </el-col>
            </el-row>
            <el-form-item
              label="密码"
              prop="password"
              style="margin-bottom: 20px"
            >
              <el-input
                v-model="registForm.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input
                v-model="registForm.checkPass"
                placeholder="确认密码"
                type="password"
              ></el-input>
            </el-form-item>
            <div class="login-tool-wrap">
              <span class="login-tool" @click="formType = 'login'"
                >已有账号？去登录</span
              >
            </div>
            <el-button class="btn" :loading="loginLoading" @click="register">注册</el-button>
          </el-form>
        </template>
        <template v-else-if="formType === 'forgot'">
          <div class="form-title">找回密码</div>
          <el-form ref="forgotForm" :model="forgotForm" :rules="forgotRules">
            <el-form-item label="邮箱账号" prop="email">
              <el-input
                v-model="forgotForm.email"
                placeholder="请输入邮箱账号"
              ></el-input>
            </el-form-item>
            <el-row type="flex" align="center">
              <el-col :span="14">
                <el-form-item label="验证码" prop="code">
                  <el-input
                    v-model="forgotForm.code"
                    placeholder="请输入验证码"
                    maxlength="6"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="8"
                :offset="2"
                style="display: flex; align-items: center; justify-content: end"
              >
                <el-button
                  class="code-btn"
                  :disabled="codeButtonStatus.status"
                  @click="getSms(forgotForm.email)"
                  >{{ codeButtonStatus.text }}</el-button
                >
              </el-col>
            </el-row>
            <el-form-item
              label="密码"
              prop="password"
              style="margin-bottom: 20px"
            >
              <el-input
                v-model="forgotForm.password"
                placeholder="请输入新密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input
                v-model="forgotForm.checkPass"
                placeholder="确认密码"
                type="password"
              ></el-input>
            </el-form-item>

            <el-button class="btn" :loading="loginLoading" @click="forgot"
              >确认</el-button
            >
          </el-form>
        </template>
        <template v-else-if="formType === 'login'">
          <div class="form-title" style="margin-bottom: 70px !important">
            欢迎回来！<br />登录
          </div>
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
            <el-form-item label="邮箱账号" prop="email" class="local-select-input">
              <el-input
                v-model="loginForm.email"
                placeholder="请输入邮箱账号"
                @focus="showLocal = true"
                @blur="showLocalBlur"
              ></el-input>
              <div class="local-select" v-if="showLocal && hisEmailOptions.length > 0">
                <div class="local-select-item" v-for="(item,index) in hisEmailOptions" :key="item.value" @click="hisEmailChange(item)">
                  <div>{{item.label}}</div>
                  <i class="el-icon-close" @click.stop="deleteLocalItem(item,index)"></i>
                </div>
              </div>
            </el-form-item>
            <div
            class="login-tool-wrap"
            style="justify-content: end"
            v-if="hisEmailOptions.length > 0"
          >
            <span class="login-tool" @click="clearHisData">清空历史</span>
          </div>
            <el-form-item label="密码" prop="password" type="password">
              <el-input
                v-model="loginForm.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
              <el-checkbox v-model="localPassCheck" size="medium" style="float:right">记住密码</el-checkbox>
            </el-form-item>
            <div class="login-tool-wrap">
              <span class="login-tool" @click="formType = 'forgot'"
                >忘记密码？</span
              >
            </div>
            <el-button class="btn" @click="login" :loading="loginLoading">登录</el-button>
            <div class="formtype-switch">没有YinQingFox账户？<span @click="formType = 'register'">立即注册</span></div>
          </el-form>
        </template>
      </div>
    </div>
    <el-dialog title="您确定要退出吗" :visible.sync="AppDialog" @close="closeDialog" width="40%">
      <div style="display:flex;align-items:center;color:rgb(245,108,108)">
        <i class="el-icon-error" style="font-size:20px;margin-right:10px"></i>
        <div>退出将关闭所有浏览器！</div>
      </div>
      <div style="padding:30px 0;display:flex;justify-content:flex-end">
        <el-button @click="minimizeToTray">最小化到托盘</el-button>
        <el-button @click="closeApp" type="primary">退出程序</el-button>
      </div>
    </el-dialog>
    <el-dialog title="更新说明" :visible.sync="updateDialog" @close="closeUpdateDialog" width="40%">
      <div class="warning-info">注意:更新会关闭当前设备已打开的浏览器，请注意保存! ! !</div>
      <span>【{{clientData.ver}}】</span>
      <span v-html="clientData.descript"></span>
      <div style="padding:30px 0;display:flex;justify-content:flex-end">
        <el-button @click="cancelUpdate">取消</el-button>
        <el-button @click="updateNow" type="primary">立即更新</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validEmail } from "@/utils/validate";
import { rsaEncryptForm } from "@/utils/jsencrypt.js";
import {mapGetters} from 'vuex'
import {setLoginInfo, getLoginInfo, removeLoginInfo} from '@/utils/auth'
import {register,registerCode,forgot,forgotCode} from '@/api/index'
export default {
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error("请输入正确的邮箱地址"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不得低于6位"));
      } else {
        callback();
      }
    };
    const validateCheckPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("再次输入你的密码"));
      } else if (value !== this.registForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    const validateForgotCheckPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("再次输入你的密码"));
      } else if (value !== this.forgotForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value.length < 6) {
        callback(new Error("验证码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      showLocal: false,
      localPassCheck:true,
      hisEmailOptions: [],
      AppDialog: false,
      updateDialog: false,
      registForm: {
        email: "",
        code: "",
        password: "",
        checkPass: "",
      },
      registRules: {
        email: [{ trigger: "blur", validator: validateEmail }],
        code: [{ trigger: "blur", validator: validateCode }],
        password: [{ trigger: "blur", validator: validatePassword }],
        checkPass: [{ trigger: "blur", validator: validateCheckPassword }],
      },
      loginForm: {
        email: "",
        password: "",
      },
      loginRules: {
        // email: [{ trigger: "blur", validator: validateEmail }],
        // password: [{ trigger: "blur", validator: validatePassword }],
      },
      loginLoading: false,
      forgotForm: {
        email: "",
        code: "",
        password: "",
        checkPass: "",
      },
      forgotRules: {
        email: [{ trigger: "blur", validator: validateEmail }],
        code: [{ trigger: "blur", validator: validateCode }],
        password: [{ trigger: "blur", validator: validatePassword }],
        checkPass: [{ trigger: "blur", validator: validateForgotCheckPassword }],
      },
      formType: "login",
      codeBtn: false,
      timer: null,
      codeButtonStatus: {
        status: false,
        text: "获取验证码",
      },
      redirect: undefined,
      otherQuery: {}
    };
  },
  computed:{
    ...mapGetters(['clientData'])
  },
  mounted(){
    this.hasHisEmailOptions()
  },
  watch:{
    clientData(newValue){
      if(newValue.type === 'msgbox'){
        this.AppDialog = true
      }else{
        this.AppDialog = false
      }
      if(newValue.type === "updataver"){
        this.updateDialog = true
      }else{
        this.updateDialog = false
      }
    },
    formType(newValue){
      this.countDown(1)
    }
  },
  methods: {
    hasHisEmailOptions(){
      if (!getLoginInfo() || getLoginInfo().length === 0) {
          this.hisEmailOptions = []
      }else{
        let hisEmailOptions = getLoginInfo();
        hisEmailOptions.map((item, index) => {
          item.label = item.email;
          item.value = index;
        });
        this.hisEmailOptions = hisEmailOptions;
      }
      
    },
    clearHisData() {
      removeLoginInfo();
      this.hasHisEmailOptions()
      this.$message({
        type: 'success',
        message: '已清空历史！'
      })
    },
    showLocalBlur(){
      setTimeout(() => {this.showLocal = false},200)
    },
    hisEmailChange({ email, password }) {
      this.loginForm.email = email;
      this.loginForm.password = password;
    },
    deleteLocalItem(item,index){
      this.hisEmailOptions.splice(index,1)
      setLoginInfo(this.hisEmailOptions)
      this.hasHisEmailOptions()
    },
    minimizeToTray(){
      window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 890)
      this.AppDialog = false
    },
    closeApp(){
      window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 888)
      this.AppDialog = false
    },
    closeDialog(){
      window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 889)
    },
    closeUpdateDialog(){
      // let data = ChromeSDK.myfunc('notice','{ form: "modal" }',892)
      // console.log(222,data)
    },
    cancelUpdate(){
      let data = ChromeSDK.myfunc('notice','{ form: "modal" }',892)
      console.log('取消更新',data)
      this.updateDialog = false
    },
    updateNow(){
      let data = ChromeSDK.myfunc('notice','{ form: "modal" }',891)
      console.log('开始更新',data)
      this.updateDialog = false
    },
    register() {
      this.$refs.registForm.validate((valid) => {
        if (valid) {
          this.loginLoading = true
          let form = {
            email: this.registForm.email,
            password: this.registForm.password,
            repassword: this.registForm.checkPass,
            code: this.registForm.code
          }
          let requestData = rsaEncryptForm(form, "SIJa%$%3hu12hu(*1278");
          register(requestData).then(res => {
            this.loginLoading = false
            if(res.code === 400){
              this.$message.error(res.msg)
            }else{
              this.loginForm.email = this.registForm.email;
              this.$message.success(res.msg)
              this.formType = 'login'
            }
          }).catch(err => {
            this.loginLoading = false
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loginLoading = true
          if(this.localPassCheck){
            let form = {
              account: this.loginForm.email,
              password: this.loginForm.password,
              dongle: "dongle",
              type: "1",
            };
            let requestData = rsaEncryptForm(form, "SIJa%$%3hu12hu(*1278");
            this.$store.dispatch("index/login", requestData).then((res) => {
                this.loginLoading = false
                window.ChromeSDK.password = this.loginForm.password
                // 用户登录信息数组
                let newArr = []
                if (!getLoginInfo() || getLoginInfo().length === 0) {
                  newArr.push(this.loginForm)
                  setLoginInfo(newArr)
                } else {
                  let data = getLoginInfo()
                  let hasLogin = data.filter(item => item.email === this.loginForm.email)
                  if(hasLogin.length === 0){
                    data.push(this.loginForm)
                    setLoginInfo(data)
                  }
                }
                this.$router.push({
                  path: "/shop",
                });
            }).catch((err) =>{
              this.loginLoading = false
            });
            
          }else{
            let form = {
              account: this.loginForm.email,
              password: this.loginForm.password,
              dongle: "dongle",
              type: "1",
            };
            let requestData = rsaEncryptForm(form, "SIJa%$%3hu12hu(*1278");
            this.$store.dispatch("index/login", requestData).then((res) => {
              this.loginLoading = false
              window.ChromeSDK.password = this.loginForm.password
              this.$router.push({
                path: "/shop",
              });
            }).catch((err) =>{
              this.loginLoading = false
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    forgot() {
      this.$refs.forgotForm.validate((valid) => {
        if (valid) {
          this.loginLoading = true
          let form = {
            email: this.forgotForm.email,
            password: this.forgotForm.password,
            repassword: this.forgotForm.checkPass,
            code: this.forgotForm.code
          }
          let requestData = rsaEncryptForm(form, "SIJa%$%3hu12hu(*1278");
          forgot(requestData).then(res => {
            this.loginLoading = false
            if(res.code === 400){
              this.$message.error(res.msg)
            }else{
              this.loginForm.email = this.forgotForm.email;
              this.$message.success(res.msg)
              this.formType = 'login'
            }
          }).catch(err => {
            this.loginLoading = false
            console.log(err)
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getSms(email) {
      if (email == "") {
        this.$message.error("邮箱不能为空");
        return false;
      }
      if (!validEmail(email)) {
        this.$message.error("请输入正确的邮箱地址");
        return false;
      }
      this.updateButtonStatus({
        status: true,
        text: "发送中",
      });
      if(this.formType === 'register'){
        let form = {
          email: this.registForm.email
        }
        let requestData = rsaEncryptForm(form, "SIJa%$%3hu12hu(*1278");
        registerCode(requestData).then(res => {
          if(res.code === 400){
            this.$message.error(res.msg)
            this.updateButtonStatus({
              status: false,
              text: "获取验证码",
            });
          }else{
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.countDown(60);
          }
        }).catch(err => {
          this.updateButtonStatus({
            status: false,
            text: "获取验证码",
          });
        })
      }
      if(this.formType === 'forgot'){
        let form = {
          email: this.forgotForm.email
        }
        let requestData = rsaEncryptForm(form, "SIJa%$%3hu12hu(*1278");
        forgotCode(requestData).then(res => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.countDown(60);
        }).catch(err => {
          this.updateButtonStatus({
            status: false,
            text: "获取验证码",
          });
        })
      }
    },
    updateButtonStatus(params) {
      this.codeButtonStatus.status = params.status;
      this.codeButtonStatus.text = params.text;
    },
    countDown(number) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      let time = number;
      this.timer = setInterval(() => {
        time--;
        this.codeButtonStatus.text = `倒计时${time}秒`;
        if (time === 0) {
          clearInterval(this.timer);
          this.updateButtonStatus({
            status: false,
            text: "再次获取",
          });
        }
      }, 1000);
    },
    clearCountDown() {
      this.updateButtonStatus({
        status: false,
        text: "获取验证码",
      });
      clearInterval(this.timer);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrap {
  background-color: #ff975e;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .main-content {
    width: 72%;
    height: 70%;
    border-radius: 30px;
    display: flex;
    background-color: #fff;
    .login-wrap,
    .banner-wrap {
      width: 50%;
    }
    .login-wrap {
      padding: 50px 120px 34px 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }
    .banner-wrap {
      padding: 36px 70px 26px 96px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #ffeee8;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
}

.logo {
  height: 46px;
  position: absolute;
  top: 36px;
  left: 92px;
}
.banner {
  height: 350px;
}
.login-img {
  height: 88px;
  position: absolute;
  bottom: 24px;
  left: 47px;
}
svg {
  width: 27px;
  height: 20px;
  position: absolute;
  left: 28px;
  top: 38px;
  cursor: pointer;
}
.form-title {
  font-size: 32px;
  margin-bottom: 34px;
}
::v-deep .el-form-item__error {
  left: unset;
  right: 0;
}
::v-deep .el-form-item__label {
  font-size: 18px;
  font-weight: normal;
}
::v-deep .el-form-item__content,
::v-deep .el-input__inner {
  border-radius: 10px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
}
::v-deep.el-input--medium .el-input__inner {
  line-height: 50px;
  height: 50px;
}
::v-deep.el-input--medium .el-input__inner {
  // border: 1px solid #f67c1f;
  border-radius: 5px;
}
::v-deep .el-form .el-input,
::v-deep .el-form-item__content {
  width: 100%;
  height: 50px;
}
::v-deep .el-form-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2px;
}
::v-deep .el-input--mini .el-input__inner {
  height: 20px;
  line-height: 20px;
}
::v-deep .el-select .el-input .el-select__caret {
  line-height: 20px;
}
.btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f67c1f;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
  margin-top: 86px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover,
.btn:active,
.btn:focus,
.btn.is-disabled,
.btn.is-disabled:hover {
  background: rgba(255, 91, 15, 0.8);
  border: unset;
  color: #fff;
}
.el-select {
  width: 92px;
  height: 20px;
  margin-left: 8px;
}
.code-btn {
  width: 100%;
  height: 50px;
  background-color: #fff;
  margin-top: 37px;
  border: 1px solid #f67c1f;
  color: #f67c1f;
  border-radius: 10px;
}
.code-btn:hover,
.code-btn:active,
.code-btn:focus,
.code-btn.is-disabled,
.code-btn.is-disabled:hover {
  background: #f67c1f;
  border: 1px solid #f67c1f;
  color: #fff;
}

.his-email {
  width: 100%;
  height: 62px;
  margin-left: unset;
}
::v-deep.his-email .el-input__inner {
  width: 100%;
  height: 62px;
  border: 1px solid #f67c1f;
}
.login-tool-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: unset !important;
}
.login-tool {
  float: right;
  font-size: 16px;
  color: #f67c1f;
  cursor: pointer;
}
::v-deep .el-checkbox__label {
  font-size: 16px;
}
::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  left: 7px;
  top: 4px;
}
// ::v-deep .el-dialog {
//   background: linear-gradient(
//     0deg,
//     rgba(255, 255, 255, 1) 0%,
//     rgba(255, 228, 221, 1) 81%,
//     rgba(255, 200, 187, 1) 100%
//   );
// }
// ::v-deep .el-dialog .el-dialog__header {
//   background: unset;
// }
// ::v-deep .el-dialog--center .el-dialog__body {
//   text-align: center;
// }
// ::v-deep .el-dialog__headerbtn {
//   display: none;
// }
::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 60px 20px 60px 60px;
    font-size: 20px;
    font-weight: bold;
  }
  .el-dialog__body {
    padding: 0 70px 20px 80px;
    color: #606266;
    font-size: 18px;
    word-break: break-all;
    p {
      margin-bottom: 36px;
    }
  }
}
::v-deep {
  .el-dialog{
    margin-top: unset !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
.dialog-cancel {
  width: 127px;
  height: 46px;
  margin-right: 24px;
  background: rgba(255, 234, 229, 1);
  border: unset;
}
.dialog-cancel:hover {
  background: rgba(255, 234, 229, 0.8);
  border: unset;
  color: #606266;
}
.dialog-confirm {
  width: 127px;
  height: 46px;
  background: #f67c1f;
  border: unset;
}
.dialog-confirm:hover {
  background: rgba(255, 91, 15, 0.8);
  border: unset;
  color: #fff;
}
::v-deep .el-dialog__footer {
  padding: 50px 0 54px 0;
}
.dialog-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 1);
  background: linear-gradient(
    162deg,
    rgba(255, 99, 44, 1) 0%,
    rgba(255, 99, 44, 1) 27.95%,
    rgba(255, 128, 58, 1) 63.26%,
    rgba(255, 156, 72, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: -45px;
  transform: translateX(-50%);
}
.dialog-img img {
  margin-left: 4px;
}
.local-select-input{
  position: relative;
}
.local-select{
  position: absolute;
  width: 100%;
  height: 30vh;
  overflow-y: auto;
  left: 0;
  top: calc(100% + 3px);
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  z-index: 999;
  padding: 10px 0;
}
.local-select-item{
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(245,247,250);
  cursor: pointer;
  &:hover{
    background-color: rgba(255, 91, 15, 0.8);
    color: #fff;
    border-radius: 10px;
  }
}
.warning-info{
  background-color: rgb(252,245,235);
  line-height: 40px;
  color: rgb(245,205,146);
  text-indent: 40px;
  margin-bottom: 20px;
}
.formtype-switch{
  text-align: center;
  margin-top: 20px;
  color: #9fa2aa;
  span{
    color: #f67c1f;
    cursor: pointer;
  }
}
</style>
