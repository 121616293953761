import Cookies from 'js-cookie'
import {setWindowCache,getWindowCache} from '@/utils/client'


export function getToken() {
  return Cookies.get('token')
}

export function setToken(token) {
  return Cookies.set('token', token)
}

export function removeToken() {
  return Cookies.remove('token')
}

export function setUserInfo(UserInfo) {
    return Cookies.set('UserInfo', UserInfo)
  }
  
  export function getUserInfo() {
    return Cookies.get('UserInfo')
  }
  
  export function removeUserInfo() {
    return Cookies.remove('UserInfo')
  }

  /**
 * 
 * @param {Array} loginInfo  用户保存的账号密码
 * @returns 
 */
export function setLoginInfo(info) {
  setWindowCache('LoginInfo', info)
}

export function getLoginInfo() {
  return getWindowCache().LoginInfo
}

export function removeLoginInfo() {
  setWindowCache('LoginInfo',[])
}
