<template>
  <div class="top-wrap">
    <div class="refresh-data">
      <svg-icon icon-class="fresh-data" @click="refreshData" style="margin-right:20px;font-size:24px;cursor: pointer;"></svg-icon>
      <!-- <el-button style="margin-right:20px" @click="refreshData">刷新数据</el-button> -->
    </div>
    <div class="user-info">
      <svg-icon icon-class="top-account"></svg-icon>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{userInfo.account}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="showDialog">个人信息</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="个人信息" :visible.sync="userDialog" width="564px" class="user-info-wp">
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="6">昵称：</el-col>
        <el-col :span="18">{{userInfo.true_name}}</el-col>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="6">账号：</el-col>
        <el-col :span="18">{{userInfo.account}}</el-col>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="6">密码：</el-col>
        <el-col :span="18">
          <el-button @click="changePassDialog = true">修改密码</el-button>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="6">最近登录时间：</el-col>
        <el-col :span="18">{{userInfo.last_time}}</el-col>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="6">到期时间：</el-col>
        <el-col :span="18">{{userInfo.expire_time}}</el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePassDialog" width="564px">
      <el-form ref="form" :model="changePassForm" :rules="formRules">
        <el-form-item label="旧密码" prop="old_password">
          <el-input v-model="changePassForm.old_password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new_password">
          <el-input v-model="changePassForm.new_password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirm_password">
          <el-input v-model="changePassForm.confirm_password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="changePassDialog = false">取消</el-button>
          <el-button @click="changePass">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {userDetail,userRestPass} from '@/api/index'
import {rsaDecrypt,rsaEncryptForm} from '@/utils/jsencrypt'
import {shopList} from '@/api/index'
import {windowRegistData} from '@/utils/client'
export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不得低于6位"));
      } else {
        callback();
      }
    };
    const validateCheckPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("再次输入你的密码"));
      } else if (value !== this.changePassForm.new_password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      changePassForm: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      formRules: {
        old_password: [{trigger: "blur", validator: validatePassword }],
        new_password: [{ trigger: "blur", validator: validatePassword }],
        confirm_password: [{ trigger: "blur", validator: validateCheckPassword }]
      },
      userDialog: false,
      changePassDialog: false,
      AppDialog: false
    };
  },
  computed:{
    ...mapGetters(['userInfo','openedBrowser'])
  },
  methods: {
    refreshData(){
      this.$store.commit('index/SET_ISREFRESH',true)
    },
    async handleCommand(item) {
      if (item === "showDialog") {
        this.userDialog = true;
        await this.$store.dispatch('index/getInfo')
      } else {
        const loading = this.$loading({
          lock: true,
          text: '处理中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        shopList().then(async res => {
          let {result} = res
          let data = this.$rsaDecrypt(result)
          if(data && data.lists.length > 0){
            for(let i=0;i<data.lists.length;i++){
              if(data.lists[i].is_use === 1){
                window.ChromeSDK.myfunc('StopProfileId',data.lists[i].uuid)
                let client = await windowRegistData()
                if(client.error === 0){
                  this.$message.success('关闭浏览器成功！')
                }else{
                  this.$message.error('关闭浏览器失败！')
                }
              }
            }
          }
          setTimeout(async() => {
            await this.$store.dispatch('index/logout')
            loading.close();
            this.AppDialog = false
          },2000)
        }).catch(err => {
          console.log('ERR',err)
          setTimeout(async() => {
            await this.$store.dispatch('index/logout')
            loading.close();
            this.AppDialog = false
          },2000)
        })
      }
    },
    changePass() {
      this.changePassDialog = true
      this.$refs.form.validate((valid) => {
        if(valid){
          let requestData = rsaEncryptForm(this.changePassForm, "SIJa%$%3hu12hu(*1278");
          userRestPass(requestData).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.changePassDialog = false
          })
        }else{
          console.log('error-busmit')
          return false
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.top-wrap {
  width: 1620px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 50px;
  
  .user-info {
    display: flex;
    align-items: center;
  }
}
.user-info{
  cursor: pointer;
}
svg {
  font-size: 22px;
  margin-right: 20px;
  fill: #ff6e00 !important;
  color: #ff6e00;
}

.el-button {
  background: rgb(255, 239, 228);
  border: unset;
  color: #606266;
  &:hover,&:active,&:focus{
    background: rgb(255, 239, 228);
    border: unset;
    color: #606266;
  }
}
::v-deep .el-col-6{
  text-align: right;
}
::v-deep .el-row--flex.is-align-middle{
  margin-bottom: 20px;
}
::v-deep .user-info-wp .el-dialog .el-dialog__header{
  padding: 3.75rem 1.25rem 1.75rem 3.75rem;
}
::v-deep {
  .el-dialog{
    margin-top: unset !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>