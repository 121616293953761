import router from './index.js'
import store from '../store/index.js'
import { Message } from 'element-ui'
import { getWindowCache, setWindowCache } from '../utils/client.js'


const whiteRouter = ['/login']

// 路由守卫
router.beforeEach(async (to, from, next) => {
    // 判断token
    if (getWindowCache().token) {
        // 判断路径是登录
        if (to.path === '/login') {
            next('')
        } else {
            const hasRoles = getWindowCache().roles && getWindowCache().roles.length > 0
            if (hasRoles) {
                next()
            } else {
                // 不是登录页且没有roles数据
                try {
                    await store.dispatch('index/getInfo')
                    const accessRoutes = await store.dispatch('permission/generateRoutes', getWindowCache().roles)
                    router.addRoutes(accessRoutes)
                    next({ ...to, replace: true })
                } catch (error) {
                    await store.dispatch('index/resetToken')
                    next('/login')
                }
            }
        }
    } else {
        // 判断是否有白名单地址    !== -1 指存在白名单地址  
        if (whiteRouter.indexOf(to.path) !== -1) {
            next()
        } else {
            // Message.error('请重新登录')
            next('/login')
        }
    }
})