<template>
   <div class="menu-container">
        <template v-for="v in menuList">
            <el-submenu :index="v.name" v-if="v.children&&v.children.length>0" :key="v.name">
                <template slot="title">
                    <svg-icon :icon-class="v.meta.icon"></svg-icon>
                    <span>{{v.meta.name}}</span>
                </template>
                <el-menu-item-group>
                    <my-nav :menuList="v.children"></my-nav>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item :key="v.name" :index="v.name" @click="gotoRoute(v.name)" v-else>
                <svg-icon :icon-class="v.meta.icon"></svg-icon>
                <span slot="title">{{v.meta.name}}</span>
            </el-menu-item>
        </template>
    </div>
</template>
<script>
export default {
  name: "my-nav",
  props: {
    menuList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    gotoRoute(name,path) {
      if(name !== this.$route.name){
        this.$router.push({ name });
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  margin-right: 20px;
  font-size: 22px;
  fill:currentColor;
  color: #ff6e00
  
}


::v-deep .el-menu-item {
    width: 262px;
    margin: 0 auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-left: unset !important;
    span{
        font-size: 18px;
        // text-align: center;
        // width: calc(100% - 44px);
    }
}
::v-deep .el-menu-item.is-active{
    background-color: #ff6e00 !important;
    svg{
      color: #fff
    }
}
</style>