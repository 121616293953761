<template>
<div class="dialog-wrap">
    <div class="title">支付订单</div>
    <div class="padding">
    <div class="order-title">订单详情</div>
    <div class="order-desc">
        <div class="name" v-if="type === 'package' || type === 'renewPackage'">{{order.name}}</div>
        <!-- <div class="price"><span>¥</span>{{order.payment_amount}}/月 <span v-if="type === 'package'">$199.00</span> </div> -->
        <div v-if="type === 'package' || type === 'renewPackage'" style="display:flex;">
          <div class="package-content" style="width:40%" v-html="order.content"></div>
          <div class="package-content ll-wrap" style="width:60%">
            <div style="display:flex;align-items:center">
              <div>期限：</div>
              <span>{{order.time_limit}}个月</span>
            </div>
            <div style="display:flex;align-items:center">
              <div>到期时间：</div>
              <span>{{order.expire_time}}</span>
            </div>
          </div>
        </div>
        <div v-if="type === 'resources'">
            <div class="main">
                <span>·</span>
                <div>地域:</div>
                <span>{{order.resources_area}}</span>
            </div>
            <div class="main">
                <span>·</span>
                <div>云平台:</div>
                <span>{{order.cloud_service_provider}}</span>
            </div>
            <div class="main">
                <span>·</span>
                <div>期限:</div>
                <span>{{order.time_limit}}</span>
            </div>
            <div class="main" v-if="!order.isRenew">
                <span>·</span>
                <div>数量:</div>
                <span>{{order.number}}</span>
            </div>
        </div>
        
    </div>
    <div class="price-info">
        <div class="ii" v-if="payStatus === 1">应付金额：<span>¥{{order.total_amount}}</span></div>
        <div class="qrcode" v-if="payStatus === 1">
          <iframe :srcdoc="order.alipay_qr_code"
            frameborder="no"
            border="0"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            width="200"
            height="200"
            style="overflow:hidden;">
          </iframe>
        </div>
        <div class="ii" v-if="payStatus === 1">请使用支付宝扫一扫</div>
        <div class="qrcode" v-if="payStatus === 2">
          <img src="@/assets/imgs/pay_success.png" alt="">
          <span>支付成功！</span>
        </div>
        
    </div>
    </div>
</div>
</template>

<script>
import {resourcesPayQuery,packagePayQuery} from '@/api/index'
export default {
    props: ['order','type'],
    data(){
        return{
          payStatus: null,
          countTimer: null
        }
    },
    mounted(){
      if(this.type === 'resources'){
        this.resourcesPayQuery()
      }
      if(this.type === 'package' || this.type === 'renewPackage'){
        this.packagePayQuery()
      }
    },
    destroyed(){
      clearTimeout(this.countTimer)
    },
    methods:{
      resourcesPayQuery(){
        let req = {order_no:this.order.order_no}
        let requestData = this.$rsaEncryptForm(req, "SIJa%$%3hu12hu(*1278")
        resourcesPayQuery(requestData).then(res => {
          let {result} = res
          let data = this.$rsaDecrypt(result)
          this.payStatus = data.status
          if(data.status === 1){
            this.countTimer = setTimeout(() => {
              this.resourcesPayQuery()
            }, 2000);
          }
          if(data.status === 2){
            setTimeout(() => {
              this.$emit('closeAll')
              clearTimeout(this.countTimer)
              return false
            },2000)
          }
        }).catch(err => {
          clearTimeout(this.countTimer)
          return false
        })
      },
      packagePayQuery(){
        let req = {order_no:this.order.order_no}
        let requestData = this.$rsaEncryptForm(req, "SIJa%$%3hu12hu(*1278")
        packagePayQuery(requestData).then(res => {
          let {result} = res
          let data = this.$rsaDecrypt(result)
          this.payStatus = data.status
          if(data.status === 1){
            this.countTimer = setTimeout(() => {
              this.packagePayQuery()
            }, 2000);
          }
          if(data.status === 2){
            this.$store.dispatch('index/getInfo')
            setTimeout(() => {
              this.$emit('closeAll')
              clearTimeout(this.countTimer)
              return false
            },2000)
          }
        }).catch(err => {
          clearTimeout(this.countTimer)
          return false
        })
      }
    }
}
</script>

<style lang="scss" scoped>
.dialog-wrap{
  padding: 0px 10px;
  .title{
    font-size: 20px;
    color: #FA8120;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
    position: relative;
    text-align: center;
    &::before{
      content: '';
      width: 80px;
      height: 3px;
      background-color: #FA8120;
      position: absolute;
      left: 50%;
      bottom: -1px;
      transform: translateX(-50%);
    }
  }
  .padding{
    padding: 0 55px;
    margin: 30px 0;
    .order-title{
      padding: 15px 28px;
      background-image: url('~@/assets/imgs/order_title.png');
      background-size: 100% 100%;
      font-weight: bold;
    }
    .order-desc{
      padding: 33px 28px;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,.3);
      margin: 30px 0;
      .name{
        font-size: 24px;
        text-align: center;
        font-weight: bold;
      }
      .price{
        color: #FF6E00;
        font-size: 34px;
        padding: 26px 0;
        span{
          &:first-child{
            font-size: 20px;
          }
          &:last-child{
            font-size: 18px;
            color: #999999;
            padding-left:26px;
            text-decoration: line-through;
          }
        }
      }
      .main{
        display: flex;
        align-items: center;
        // justify-content: space-between;
        font-size: 18px;
        span{
          &:first-child{
            font-weight: bold;
            font-size: 30px;
          }
        }
        div{
          width: calc(30%);
          color: #999999;
          padding: 0 20px;
        }
      }
    }
    .price-info{
      padding: 40px 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,.3);
      .ii{
        font-size: 20px;
        margin: 15px 0;
        span{
          color: #ED6B08;
          font-size: 24px;
          font-weight: bold;
        }
      }
      .info{
        div{
          &:first-child{
            padding-bottom: 24px;
            font-size: 18px;
            span{
              font-size: 20px;
              color: #ED6B08;
            }
          }
          &:last-child{
            font-size: 16px;
          }
        }
      }
      .qrcode{
        // width: 100px;
        // height: 100px;
        // background-color: #FEF6F0;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width:100px;
        }
        span{
          font-size: 20px;
          padding-top: 20px;
        }
      }
    }
  }
  
}
::v-deep {
  .package-content{
    padding-top: 10px;
    font-size: 18px;
    // display: flex;
    // flex-wrap: wrap;
    width: 50%;
    p{
      width: 50%;
      margin: 0;
      margin-bottom: unset !important;
      padding: 10px 0;
      white-space: nowrap;
    }
  }
  .ll-wrap{
    >div{
      padding: 10px 0;
      div{
        flex: none;
      }
    }
  }
}
  

</style>
<style lang="scss">
.el-dialog{
    margin-top: unset !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
</style>