import service from '../utils/request'



export function login(data) {
    return service({
        url: '/api_login_login',
        method: 'post',
        data
    })
}
export function register(data){
    return service({
        url: '/api_register_account',
        method: 'post',
        data
    })
}
export function registerCode(data){
    return service({
        url: '/api_register_code',
        method: 'post',
        data
    })
}
export function forgot(data){
    return service({
        url: '/api_reset_password',
        method: 'post',
        data
    })
}
export function forgotCode(data){
    return service({
        url: '/api_reset_code',
        method: 'post',
        data
    })
}

export function logout() {
    return service({
        url: '/api_log_out',
        method: 'post'
    })
}

// 用户
export function userDetail() {
    return service({
        url: '/api_user_detail',
        method: 'post'
    })
}

export function userRestPass(data) {
    return service({
        url: '/api_user_reset_passwd',
        method: 'post',
        data
    })
}

// 店铺
export function addShop(data) {
    return service({
        url: '/api_browser_configures',
        method: 'post',
        data
    })
}


export function shopList() {
    return service({
        url: '/api_user_browser',
        method: 'post'
    })
}

export function shopEdit(data) {
    return service({
        url: '/api_edit_browser',
        method: 'post',
        data
    })
}

export function shopDelete(data) {
    return service({
        url: '/api_delete_browser',
        method: 'post',
        data
    })
}

export function freedBrowser(data) {
    return service({
        url: '/api_browser_release_config',
        method: 'post',
        data
    })
}

// 子账户
export function subaccList() {
    return service({
        url: '/api_company_user_list',
        method: 'post'
    })
}

export function subaccAdd(data) {
    return service({
        url: '/api_add_company_user',
        method: 'post',
        data
    })
}

export function subaccEdit(data) {
    return service({
        url: '/api_edit_company_user',
        method: 'post',
        data
    })
}

export function subaccDelete(data) {
    return service({
        url: '/api_delete_company_user',
        method: 'post',
        data
    })
}

// 分组
export function groupList() {
    return service({
        url: '/api_browser_group_list',
        method: 'post'
    })
}

export function groupAdd(data) {
    return service({
        url: '/api_browser_group_add',
        method: 'post',
        data
    })
}

export function groupEdit(data) {
    return service({
        url: '/api_browser_group_edit',
        method: 'post',
        data
    })
}

export function groupDelete(data) {
    return service({
        url: '/api_browser_group_delete',
        method: 'post',
        data
    })
}

// 浏览器版本信息
export function getBrowserVerList() {
    return service({
        url: '/api_browser_version',
        method: 'post'
    })
}

// 获取浏览器配置信息
export function getBrowserConfig(data) {
    return service({
        url: '/api_get_browser_configures',
        method: 'post',
        data
    })
}

// 资源列表
export function getResources(data) {
    return service({
        url: '/api_resources',
        method: 'post',
        data
    })
}

// 资源列表 区域价格
export function resourcesArea(data) {
    return service({
        url: '/api_resources_area',
        method: 'post',
        data
    })
}

// 资源订单
export function resourcesOrder(data) {
    return service({
        url: '/api_resources_pay_order',
        method: 'post',
        data
    })
}

// 资源订单查询
export function resourcesPayQuery(data) {
    return service({
        url: '/api_resources_payment_status',
        method: 'post',
        data
    })
}

// 资源订单续费
export function resourceRenewOrder(data) {
    return service({
        url: '/api_resources_renewal_order',
        method: 'post',
        data
    })
}

// 套餐列表
export function packageLists(data) {
    return service({
        url: '/api_schemes',
        method: 'post',
        data
    })
}

// 套餐支付订单
export function packageOrder(data) {
    return service({
        url: '/api_schemes_pay_order',
        method: 'post',
        data
    })
}

// 套餐订单查询
export function packagePayQuery(data) {
    return service({
        url: '/api_schemes_payment_status',
        method: 'post',
        data
    })
}

// 资源编辑名称
export function resourceEditName(data) {
    return service({
        url: '/api_resources_edit_name',
        method: 'post',
        data
    })
}


