export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 邮箱验证
export function validEmail(value) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(value)
}

// 密码验证 字母开头,长度在6-18之间
export function validPass(value) {
  let reg = /^[a-zA-Z]\w{5,17}$/;
    return reg.test(value)
}

// 数字
export function validSort(value) {
  let reg = /^[0-9]*$/;
    return reg.test(value)
}