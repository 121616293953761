import axios from 'axios'
import store from '@/store/index.js'
import { Message } from 'element-ui';
import router, { resetRouter } from '@/router/index'
import { removeToken, removeUserInfo } from '@/utils/auth'
import { getWindowCache, setWindowCache } from '../utils/client.js'


const BASEURL = process.env.NODE_ENV === 'production' ? 'https://etapi.softapi.cn' : '/api';

const service = axios.create({
    baseURL: BASEURL,
    timeout: 200000,
});

// 请求接口前 做一些数据处理 (请求拦截器)
service.interceptors.request.use(function (config) {
    if (getWindowCache().token) {
        config.headers['token'] = getWindowCache().token
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 请求接口后 返回数据进行拦截 (响应拦截器)
service.interceptors.response.use(
    res => {
        if (res.data.code === 200) {
            return res.data
        } else if (res.data.code === 400 && res.data.msg === '请重新登录！') {
            if (router.history.current.fullPath === '/login') {
                return res.data
            } else {
                Message.error({
                    message: res.data.msg,
                    duration: 3000
                })
                setTimeout(() => {
                    store.commit('index/SET_TOKEN', '')
                    store.commit('index/SET_ROLES', [])
                    removeToken()
                    resetRouter()
                    removeUserInfo()
                    window.ChromeSDK.myfunc('notice', '{ "form": "887" }', 887)
                    window.location.reload()
                }, 1000)
            }
        }else{
            Message.error({
                message: res.data.msg,
                duration: 3000
            })
            return Promise.reject(res.data.msg)
        }
    },
    err => {
        Message.error({
            message: err.message,
            duration: 3000
        })
        return Promise.reject(err.response)
    }
);


export default service
