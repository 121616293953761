import Vue from 'vue'
import Router from 'vue-router'


import Login from '@/views/login/index'
import Layout from '@/views/layout/index'

Vue.use(Router)

// 初始化路由
export const constantRoutes = [
  {
    path: '',
    component: Layout,
    redirect: '/shop',
    hidden: true,
    meta: {
      name: "首页"
    }
  },
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    component: Login
  }
]

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    name: 'index',
    meta: {
      roles: ['admin', 'useaer'] // you can set roles in root nav
    },
    children: [
      {
        path: "shop",
        component: () => import('@/views/shop/index.vue'),
        name: "shop",
        meta:{
          name: "店铺",
          icon: "nav-shop",
          roles: ['admin', 'useaer']
        }
      },
      {
        path: "group",
        component: () => import('@/views/group/index.vue'),
        name: "group",
        meta:{
          name: "分组",
          icon: "nav-group",
          roles: ['admin']
        },
      },
      {
        path: "subAccount",
        component: () => import('@/views/subAccount/index.vue'),
        name: "subAccount",
        meta:{
          name: "子账号",
          icon: "nav-subAccount",
          roles: ['admin']
        }
      }
      ,
      {
        path: "resourcesManage",
        component: () => import('@/views/resourcesManage/index.vue'),
        name: "resourcesManage",
        meta:{
          name: "资源管理",
          icon: "acc-manage",
          roles: ['admin']
        }
      }
      ,
      {
        path: "packageManage",
        component: () => import('@/views/packageManage/index.vue'),
        name: "packageManage",
        meta:{
          name: "套餐管理",
          icon: "order-manage",
          roles: ['admin']
        }
      }
    ]
  },
  { 
    path: '*',
    redirect: '/404',
    hidden: true,
    component: () => import('@/views/errorPage/404'),
  }
]


const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
const router = createRouter()

// 重置路由
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
