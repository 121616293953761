import md5 from 'js-md5'

// jsencrypt在public/index.html已经全局引入直接调用new JSEncrypt()即可
//TODO 如果想要测试密钥加密，然后公钥解密，需要修改 RSAKey.prototype.encrypt 中的doPublic 为 doPrivate

let pubKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDLv5zOSr/BkMW2Hl5/1fN444so
iNXyiu4tbZbT3AIhXcpdZIBPwwIMpTf+6YailnGtIQY3jCYJo2UErUbgM4UsMBri
2p07v5vGAgrXt56Fb8VZPZxK7LzrUEYVnJCi09ZKQeIi81CkxkUW70lQbBVDxwR7
uN+hK7aVWmlRib5phwIDAQAB-----END PUBLIC KEY-----`
let prvKey = `-----BEGIN PRIVATE KEY-----
    MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAMu/nM5Kv8GQxbYe
    Xn/V83jjiyiI1fKK7i1tltPcAiFdyl1kgE/DAgylN/7phqKWca0hBjeMJgmjZQSt
    RuAzhSwwGuLanTu/m8YCCte3noVvxVk9nErsvOtQRhWckKLT1kpB4iLzUKTGRRbv
    SVBsFUPHBHu436ErtpVaaVGJvmmHAgMBAAECgYAPSVgFbjxG1IrQ6CRk6PN0bXYC
    W4nZwayvAdrgGFCcmkHVkfXChjoAIuE5oeG1Dx6GQ8UIo94yuOyaVjnMFnF2RfPv
    1BQRiW1Sa5JZPUSBqCNk0GDaAxSzjuFJSBooIySLOXYFo7CxUFTk3fAHdkW4Uh6q
    8Z/wDzXLqPAPvkWTKQJBAO6ycm9QcYZ5oLbkNz3/Uk2rZme2kIpCI7+FoURc0Pr+
    fjapgXME3t/AwT2aRDMkqHLwClpLWY0InnS5Po8dEfUCQQDahJ4G0kAszS9Loaog
    gEcKTivo6xIDKjPu1wq87IgBGvDaX0Hfw7qZ62liFTql5qGwSUjmeR5gkw3Rwv11
    iZQLAkEA6kwcyajTlmUC3SMMGn3pArYwkbMBE8UyI8d+cYUN2KE/sFYX65enUUh6
    Jgar1OuxsQhvkQwPPcFtEDPY9U9alQJAVe/97hTV5Ei1pvrnW2zXg3gG0M1SAiig
    z/eGY5JqUnTz8A2ybHwfcNUiVs6eiAxR3nCT2QAdMEHlF2+mGomGzQJAMGYpO0Ky
    izsCC0b9qTC7M5JIVyVCG1BqQ1S2hbNjx8lkLW1wcGBXFh7s9J8U4VepI7Qsf8AC
    aZZTS30/xYOh4g==
    -----END PRIVATE KEY-----`


// 加密
export function rsaEncrypt(params) {
    var sign = new JSEncrypt();
    sign.setPrivateKey(pubKey);
    var signature = sign.encryptLong(params);
    return signature
}

// 解密
export function rsaDecrypt(params) {
    var verify = new JSEncrypt();
    verify.setPublicKey(pubKey);
    // var verified = verify.decrypt(params);
    var verified = verify.decryptLong(params);
    return JSON.parse(verified)
}

/**
 * @param {Object} form 表单信息
 * @param {String} sign_key 签名key
 * @returns 加密后的请求信息
 */
export function rsaEncryptForm(form, sign_key) {
    let s = {
        sign_key: sign_key,
        params: "",
    };
    Object.values(form).forEach(item => {
        s.params = `${s.params}${item}`
    })
    console.log(s)
    let requestData = {
        s: `${md5(s.params + s.sign_key)}`,
        data: rsaEncrypt(JSON.stringify(form)),
    };
    return requestData
}