<template>
  <div class="main-wrap">
    <menuNav />
    <div class="right-wrap">
      <topAside ref="topAside" />
      <mainContent />
    </div>
    <el-dialog title="您确定要退出吗" :visible.sync="AppDialog" @close="closeDialog" width="40%">
      <div style="display:flex;align-items:center;color:rgb(245,108,108)">
        <i class="el-icon-error" style="font-size:20px;margin-right:10px"></i>
        <div>退出将关闭所有浏览器！</div>
      </div>
      <div style="padding:30px 0;display:flex;justify-content:flex-end">
        <el-button @click="minimizeToTray">最小化到托盘</el-button>
        <el-button @click="closeApp" type="primary">退出程序</el-button>
      </div>
    </el-dialog>
    <el-dialog title="更新说明" :visible.sync="updateDialog" @close="closeUpdateDialog" width="40%">
      <div class="warning-info">注意:更新会关闭当前设备已打开的浏览器，请注意保存! ! !</div>
      <span>【{{clientData.ver}}】</span>
      <span v-html="clientData.descript"></span>
      <div style="padding:30px 0;display:flex;justify-content:flex-end">
        <el-button @click="cancelUpdate">取消</el-button>
        <el-button @click="updateNow" type="primary">立即更新</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import menuNav from './component/menu-nav.vue'
import topAside from './component/top-aside.vue'
import mainContent from './component/content.vue'
import {mapGetters} from 'vuex'
import {shopList} from '@/api/index'
import {windowRegistData} from '@/utils/client'
export default {
  components:{
    menuNav,
    topAside,
    mainContent
  },
  data(){
    return{
      AppDialog: false,
      updateDialog: false
    }
  },
  computed:{
    ...mapGetters(['clientData'])
  },
  watch:{
    clientData(newValue){
      if(newValue.type === 'msgbox'){
        this.AppDialog = true
      }else{
        this.AppDialog = false
      }
      if(newValue.type === "updataver"){
        this.updateDialog = true
      }else{
        this.updateDialog = false
      }
    }
  },
  methods:{
    minimizeToTray(){
      window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 890)
      this.AppDialog = false
    },
    closeApp(){
      const loading = this.$loading({
        lock: true,
        text: '处理中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      shopList().then(async res => {
        let {result} = res
        let data = this.$rsaDecrypt(result)
        console.log(data)
        if(data && data.lists.length > 0){
          for(let i=0;i<data.lists.length;i++){
            if(data.lists[i].is_use === 1){
              window.ChromeSDK.myfunc('StopProfileId',data.lists[i].uuid)
              let client = await windowRegistData()
              if(client.error === 0){
                this.$message.success('关闭浏览器成功！')
              }else{
                this.$message.error('关闭浏览器失败！')
              }
            }
          }
        }
        setTimeout(() => {
          loading.close();
          window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 888)
          this.AppDialog = false
        },2000)
      }).catch(err => {
        console.log('ERR',err)
        setTimeout(() => {
          loading.close();
          window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 888)
          this.AppDialog = false
        },2000)
      })
      
    },
    closeDialog(){
      window.ChromeSDK.myfunc('notice', '{ "form": "modal" }', 889)
    },
    closeUpdateDialog(){
      // let data = ChromeSDK.myfunc('notice','{ form: "modal" }',892)
      // console.log(222,data)
    },
    cancelUpdate(){
      let data = ChromeSDK.myfunc('notice','{ form: "modal" }',892)
      console.log('取消更新',data)
      this.updateDialog = false
    },
    updateNow(){
      let data = ChromeSDK.myfunc('notice','{ form: "modal" }',891)
      console.log('开始更新',data)
      this.updateDialog = false
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrap{
  display: flex;
}
.right-wrap{
  width: 80vw;
  height: 100vh;
}
::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 60px 20px 60px 60px;
    font-size: 20px;
    font-weight: bold;
  }
  .el-dialog__body {
    padding: 0 60px 20px 60px;
    color: #606266;
    font-size: 18px;
    word-break: break-all;
    p {
      margin-bottom: 36px;
    }
  }
}
.warning-info{
  background-color: rgb(252,245,235);
  line-height: 40px;
  color: rgb(245,205,146);
  text-indent: 40px;
  margin-bottom: 20px;
}
::v-deep {
  .el-dialog{
    margin-top: unset !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>