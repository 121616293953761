import { login, logout, userDetail,subaccList,shopList } from '@/api/index'
import { getToken, setToken, removeToken, setUserInfo, getUserInfo, removeUserInfo} from '@/utils/auth'
import { Message } from 'element-ui';
import { rsaDecrypt } from '@/utils/jsencrypt'
import router, { resetRouter } from '@/router'
import {setWindowCache,getWindowCache} from '@/utils/client'

const state = {
    token: getToken(),
    userInfo: getUserInfo(),
    roles: [],
    clientData: {},
    isRefreshData: false,
    openedBrowser: [],
    subAccNum: null,
    shopAccNum: null,
    selectedId: null
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_CLIENTDATA: (state, clientData) => {
        state.clientData = clientData
    },
    SET_ISREFRESH: (state, data) => {
        state.isRefreshData = data
    },
    SET_OPENEDBROWSER: (state, data) => {
        state.openedBrowser = data
    },
    SET_SUBACCNUM: (state, data) => {
        state.subAccNum = data
    },
    SET_SHOPACCNUM: (state, data) => {
        state.shopAccNum = data
    },
    SET_SELECTEDID: (state, selectedId) => {
        state.selectedId = selectedId
    },
}

const actions = {
    login({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                if(response.code === 400){
                    Message.error(response.msg)
                    reject()
                  }else{
                    let data = rsaDecrypt(response.result)
                    window.ChromeSDK.token = data.token
                    commit('SET_TOKEN', data.token)
                    setToken(data.token)
                    setWindowCache('token',data.token)
                    Message.success({
                        message: response.msg
                    })
                    resolve()
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    getInfo({ commit,state }) {
        return new Promise((resolve, reject) => {
            userDetail().then(response => {
                let { result, code } = response
                let data = rsaDecrypt(result)
                console.log(data)
                window.ChromeSDK.username = data.true_name
                setUserInfo(data)
                let rolesDir = {
                    1: 'admin',
                    2: 'useaer',
                }
                let roles = [rolesDir[data.type]]
                commit('SET_ROLES', roles)
                setWindowCache('roles',roles)
                commit('SET_USERINFO', data)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 子账户列表
    getSubAccList({ commit,state }){
        return new Promise((resolve,reject) => {
            subaccList().then(res => {
                let {result} = res
                let data = rsaDecrypt(result)
                commit('SET_SUBACCNUM',data.lists.length) 
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 店铺列表
    getShopList({ commit,state }){
        return new Promise((resolve,reject) => {
            shopList().then(res => {
                let {result} = res
                let data = rsaDecrypt(result)
                commit('SET_SHOPACCNUM',data.lists.length) 
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    logout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            logout().then((response) => {
                console.log(response)
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                removeToken()
                resetRouter()
                removeUserInfo()
                setWindowCache('token','')
                setWindowCache('roles',[])
                Message.success({
                    message: response.msg
                })
                window.ChromeSDK.myfunc('notice', '{ "form": "logout" }', 886)
                window.ChromeSDK.token = ''
                router.push({ path: '/login' })
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            setWindowCache('token','')
            removeToken()
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}