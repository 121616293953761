<template>
  <div class="aside-wrap">
    <div class="logo">
      <img src="@/assets/imgs/logo.png" alt="" />
    </div>
    <el-menu
      background-color="#ffefe4"
      text-color="#4c4c4c"
      active-text-color="#ffffff"
      :default-active="activeMenu"
      class="slide-menu"
    >
      <DynamicMenu :menuList="permission_routes[2].children" />
    </el-menu>
    <div class="bottom">
      <div class="title">有效期: {{userInfo.expire_time}}</div>
      <div class="flex" v-if="roles[0] === 'admin'">
        <div>店铺数：</div>
        <div>{{shopAccNum}}/{{userInfo.browser_num}}</div>
      </div>
      <div class="flex" v-if="roles[0] === 'admin'">
        <div>子账户数量：</div>
        <div>{{subAccNum}}/{{userInfo.user_num}}</div>
      </div>
      <template v-if="roles[0] === 'admin'">
        <el-button v-if="userInfo.schemes_id !== 0" plain class="btn" @click="renewDialog = true">续费 ></el-button>
        <el-button v-else plain class="btn">未购买套餐</el-button>
      </template>
    </div>
    <div class="sliderbar-img"></div>
    <PackageRenew :renewDialog.sync="renewDialog" v-if="renewDialog" @closeAll="closeAll" />
  </div>
</template>
  
  <script>
import router from "@/router/index"; // 初始化路由,根路由
import DynamicMenu from "@/components/dynamic-menu.vue";
import PackageRenew from '@/components/package-renew.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    DynamicMenu,
    PackageRenew,
  },
  data() {
    return {
      renewDialog: false,
      sidebarMenu: router.options.routes,
    };
  },
  computed: {
    ...mapGetters(['userInfo','subAccNum','shopAccNum',"permission_routes",'roles']),
    activeMenu() {
      return this.$route.name;
    },
  },
  mounted(){
    if(this.roles[0] === 'admin'){
        this.$store.dispatch('index/getSubAccList')
    }
    
  },
  methods:{
    closeAll(){
      this.renewDialog = false
    },
  }
};
</script>
  
<style lang="scss" scoped>
::v-deep {
  .el-dialog{
    margin-top: unset !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
.el-menu {
  border-right: unset;
  padding-top: 70px;
}
.aside-wrap {
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.logo {
  width: 300px;
  height: 112px;
  background-color: rgb(255, 245, 238);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 190px;
  }
}
.slide-menu {
  width: 300px;
  height: calc(100vh - 112px);
}
::v-deep{
  .bottom{
    background-color: rgb(255, 239, 228);
    width: 100%;
    padding: 0 50px 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
      font-style: italic;
      line-height: 50px;
      font-size: 16px;
      white-space: nowrap;
    }
    .flex{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
    }
    .btn{
      background: #fff;
      color: #FBA243;
      border-radius: 10px;
      z-index: 99;
      // width: 89px;
      // height: 32px;
      display: flex;
      font-size: 18px;
      border: unset;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      &:hover,&:active,&:focus{
        color: #FBA243;
      }
    }
  }
}
.sliderbar-img {
  width: 300px;
  height: 160px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url("~@/assets/imgs/sliderbar.png");
  background-size: 100% 100%;
}
</style>