<template>
  <div>
    <el-dialog :visible.sync="renewDialog" width="40%" @close="closeAll">
        <div class="dialog-wrap">
        <div class="title">续费订单</div>
        <div class="padding">
            <div class="order-title">订单详情</div>
            <div class="order-desc">
                <div class="name">{{userInfo.schemes_name}}</div>
                <div style="display:flex;">
                    <div class="package-content" style="width:40%" v-html="packageInfo.content"></div>
                    <div class="package-content ll-wrap" style="width:60%">
                      <div style="display:flex;">
                        <div>价格：</div>
                        <span>{{packageInfo.price}} / 月</span>
                      </div>
                      <div style="display:flex;">
                        <div>续费时长：</div>
                        <span>{{activeLimit}}个月</span>
                      </div>
                    </div>
                </div>
                <div class="limit-wrap">
                  <div class="limit-item" v-for="item in limitList" :key="item.limit" :class="activeLimit === item.limit ? 'limit-active':''" @click="activeLimit = item.limit">
                    {{item.label}}
                    <div class="score-price" v-if="item.score !== 0">{{item.score}}折</div>
                  </div>
                </div>
                <el-button class="order-btn-renew" :loading="btnLoading" @click="renewPackage">提交订单</el-button>
            </div>
        </div>
        </div>
    </el-dialog>
    <el-dialog :visible.sync="showDialogRenew" width="40%">
        <PayInfo v-if="showDialogRenew" :order="order" :type="'renewPackage'" @closeAll="closeAll" />
    </el-dialog>
  </div>
</template>

<script>
import PayInfo from '@/components/pay-info.vue'
import {mapGetters} from 'vuex'
import {packageOrder,packageLists} from '@/api/index'
export default {
    components:{
        PayInfo
    },
    props: ['renewDialog'],
    data(){
        return{
            packageInfo:{},
            btnLoading: false,
            limitList: [
              {
                label: '一个月',
                limit: 1,
                score: 0
              },
              {
                label: '三个月',
                limit: 3,
                score: 93
              },
              {
                label: '六个月',
                limit: 6,
                score: 85
              },
              {
                label: '十二个月',
                limit: 12,
                score: 75
              },
            ],
            activeLimit: 1,
            showDialogRenew: false,
        }
    },
    computed:{
        ...mapGetters(['userInfo']),
    },
    created(){
      this.packageLists()
    },
    methods:{
        packageLists(){
          packageLists().then(res => {
            let {result} = res
            let data = this.$rsaDecrypt(result)

            this.packageList = data.lists
            this.packageInfo = data.lists.find(item => item.id === this.userInfo.schemes_id)
          }).catch(err => {
            console.log(err)
          })
      },
        renewPackage(){
            this.btnLoading = true
            let req = {
                time_limit: this.activeLimit,
                schemes_id: this.userInfo.schemes_id,
                buy_type: 2
            }
            let requestData = this.$rsaEncryptForm(req, "SIJa%$%3hu12hu(*1278")
            packageOrder(requestData).then(res => {
                this.btnLoading = false
                this.order = {
                    name: this.userInfo.schemes_name,
                    user_num: this.userInfo.user_num,
                    browser_num: this.userInfo.browser_num,
                    time_limit: this.activeLimit,
                    total_amount: res.result.total_amount,
                    alipay_qr_code: res.result.alipay_qr_code,
                    order_no: res.result.order_no,
                    expire_time: res.result.expire_time,
                    content: this.packageInfo.content
                }
                console.log(111,res)
                this.showDialogRenew = true
            }).catch(err => {
                this.btnLoading = false
            })
        },
        closeAll(){
          this.$store.dispatch('index/getInfo')
          this.showDialogRenew = false
          this.$emit('closeAll')
        }
    }
}
</script>

<style lang="scss" scoped>
.order-btn-renew{
  width: 100%;
  background-color: #FF6E00;
  color: #fff;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: unset;
  font-size: 18px;
  &:hover{
    color: #fff;
    border-color: #FF6E00;
    background-color: #FF6E00;
  }
}
.dialog-wrap{
  padding: 0px 10px;
  .title{
    font-size: 20px;
    color: #FA8120;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
    position: relative;
    text-align: center;
    &::before{
      content: '';
      width: 80px;
      height: 3px;
      background-color: #FA8120;
      position: absolute;
      left: 50%;
      bottom: -1px;
      transform: translateX(-50%);
    }
  }
  .padding{
    padding: 0 55px;
    margin: 30px 0;
    .order-title{
      padding: 15px 28px;
      background-image: url('~@/assets/imgs/order_title.png');
      background-size: 100% 100%;
      font-weight: bold;
    }
    .order-desc{
      padding: 33px 28px;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,.3);
      margin: 30px 0;
      .name{
        font-size: 24px;
        text-align: center;
        font-weight: bold;
      }
      .price{
        color: #FF6E00;
        font-size: 34px;
        padding: 26px 0;
        span{
          &:first-child{
            font-size: 20px;
          }
          &:last-child{
            font-size: 18px;
            color: #999999;
            padding-left:26px;
            text-decoration: line-through;
          }
        }
      }
      .main{
        display: flex;
        align-items: center;
        // justify-content: space-between;
        font-size: 18px;
        span{
          &:first-child{
            font-weight: bold;
            font-size: 30px;
          }
        }
        div{
          width: calc(30%);
          color: #999999;
          padding: 0 20px;
        }
      }
    }
    .price-info{
      padding: 40px 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,.3);
      .ii{
        font-size: 20px;
        margin: 15px 0;
        span{
          color: #ED6B08;
          font-size: 24px;
          font-weight: bold;
        }
      }
      .info{
        div{
          &:first-child{
            padding-bottom: 24px;
            font-size: 18px;
            span{
              font-size: 20px;
              color: #ED6B08;
            }
          }
          &:last-child{
            font-size: 16px;
          }
        }
      }
      .qrcode{
        // width: 100px;
        // height: 100px;
        // background-color: #FEF6F0;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width:100px;
        }
        span{
          font-size: 20px;
          padding-top: 20px;
        }
      }
    }
  }
  
}
.limit-wrap{
  display: flex;
  align-items: center;
  padding-top: 20px;
  .limit-item{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 20px;
    background-color: rgb(254,246,240);
    text-align: center;
    line-height: 100px;
    cursor: pointer;
    position: relative;
    .score-price{
      position: absolute;
      background-image: url('~@/assets/imgs/month.png');
      background-size: 100% 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 60px;
      height: 20px;
      font-size: 15px;
      text-align: center;
      line-height: 20px;
      color: #fff;
    }
  }
  .limit-active{
    border: 1px solid #ED514C;
    color: #ED514C;
  }
}
::v-deep {
  .package-content{
    padding-top: 10px;
    font-size: 18px;
    // display: flex;
    // flex-wrap: wrap;
    width: 50%;
    p{
      width: 50%;
      margin: 0;
      margin-bottom: unset !important;
      padding: 10px 0;
      white-space: nowrap;
    }
  }
  .ll-wrap{
    >div{
      padding: 10px 0;
      div{
        flex: none;
      }
    }
  }
}
</style>