import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './icons/index.js'
import './router/guard.js'
import md5 from 'js-md5'
import { Base64 } from 'js-base64'
import './rem'


import { rsaEncryptForm, rsaDecrypt } from '@/utils/jsencrypt'
import { windowRegistData , setWindowCache,getWindowCache,isJsonString} from '@/utils/client'
windowRegistData()


let data = window.ChromeSDK.cacheinfo
if(isJsonString(data)){
  setWindowCache('token', getWindowCache().token)
  setWindowCache('roles', []) // 需重置一下roles来获取路由信息
  window.ChromeSDK.token = getWindowCache().token // 客户端需要的token
}else{
  window.ChromeSDK.cacheinfo = `{}`
  window.ChromeSDK.token = ''
}




Vue.prototype.$rsaEncryptForm = rsaEncryptForm
Vue.prototype.$rsaDecrypt = rsaDecrypt
Vue.prototype.$md5 = md5
Vue.prototype.$Base64 = Base64;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
